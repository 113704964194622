import dashboardLogo from '../../assets/dashboard.png';
import planningLogo from '../../assets/planning.png';
import IdeaLogo from '../../assets/idea1.png';
import netWorkingLogo from '../../assets/networking.png';
import roadmap1 from "../../assets/roadmap1.png"
import roadmap2 from '../../assets/roadmap2.png';
import roadmap3 from '../../assets/roadmap3.png';
import roadmap4 from '../../assets/roadmap4.png';
import idea4Logo from '../../assets/idea4.png';
import idea1Logo from '../../assets/idea1.png';
import idea2Logo from '../../assets/idea2.png';
import idea3Logo from '../../assets/idea3.png';
import solution1Logo from '../../assets/drapeau-national.png';
import solution2Logo from '../../assets/networking.png';
import solution3Logo from '../../assets/planning.png';
import solution4Logo from '../../assets/dashboard.png';
import imageEvalNat from '../../assets/image1.png';
import imageDash from '../../assets/imageDashBoard.webp';
import imageGroupe from '../../assets/imageGroupe.webp';
import imageProgress from '../../assets/imageProgression.webp';
import gifImportEval from '../../assets/ImportEval.gif';
import DashboardGif from '../../assets/DashboardGif.gif';
import EvalNatGif from '../../assets/EvaluationNationale.gif';
import DetailEleveGif from '../../assets/EleveDetail.gif';
import EvalBilanGif from '../../assets/EvalBilan.gif';
import QCMInteractifGif from '../../assets/QCMInteractif.gif';
import FeedBackGif from '../../assets/FeedBack.gif';
import ProgressionBilanGif from '../../assets/ProgressionBilan.gif';
import AdminGroupGif from '../../assets/AdminGroup.gif';
import GroupeBesoinsGif from '../../assets/GroupeBesoins.gif';
import OutilSuiviGif from '../../assets/OutilSuivi.gif';
import ProgressionCommuneGif from '../../assets/ProgressionCommune.gif';
import SpiraleGif from '../../assets/SpiraleProgression.gif';
export const heroIcons = [dashboardLogo, planningLogo, IdeaLogo, netWorkingLogo];


export const roadmap = [
    {
        id: "0",
        title: "Extension des fonctionnalités actuelles à la matière Français",
        text: "Ajoute l'exploitation des résultats d'évaluations pour la matière Français, en plus des mathématiques.",
        date: "Mai 2025",
        status: "En cours",
        imageUrl: roadmap4,
      },
      {
        id: "1",
        title: "Plan personnalisé pour l'élève par l'I.A.",
        text: "Génère des QCM en fonction du niveau de l'élève en classe et dans l'application. Propose des questions différenciées ainsi qu'une aide en temps réel par l'I.A.",
        date: "Novembre 2025",
        status: "En cours",
        imageUrl: roadmap2,
      },
     
      {
        id: "2",
        title: "Génération de contenu différencié par l'I.A.",
      text: "Permet à l'I.A. de générer du contenu pédagogique tel que des cours, des évaluations, des exercices avec corrections, et des méthodes de résolution que le professeur pourra compléter en ligne puis imprimer.",
      date: "Février 2026",
      status: "En cours",
      imageUrl: roadmap1,
      colorful: true,
      },
      {
        id: "3",
        title: "Suggestion par l'I.A.",
        text: "Analyse les résultats d'un élève sur une évaluation prévue pour anticiper les risques d'échec et proposer des coups de pouce, ainsi que des éléments à retravailler.",
        date: "Mai 2026",
        status: "En cours",
        imageUrl: roadmap3,
      },

  ];
  export const product1 = [
    {
        id: "0",
        title: "Import/Export d'évaluations",
        text: "Permet aux professeurs d'importer et d'exporter des évaluations depuis Pronote ou d'effectuer une saisie manuelle en fonction des attendus définis par Eduscol.",
        date: "2024",
        status: "Opérationnel",
        imageUrl: gifImportEval,
        colorful: true,
      },
      {
        id: "1",
        title: "Dashboard d'analyse des résultats",
        text: "Fournit une vue complète sur les résultats de la classe, incluant des analyses par thèmes, par savoir-faire et par évaluation pour mieux cerner les acquis et besoins des élèves.",
        date: "2024",
        status: "Opérationnel",
        imageUrl: DashboardGif,
      },
      {
        id: "2",
        title: "Exploitation des résultats de l'évaluation nationale",
        text: "Intègre les résultats des évaluations nationales grâce à un import, permettant une analyse comparative et un suivi détaillé des compétences clés.",
        date: "2024",
        status: "Opérationnel",
        imageUrl: EvalNatGif,
      },
      {
        id: "3",
        title: "Outil de suivi individuel des élèves",
        text: "Offre une vue individualisée sur l'acquisition des compétences pour chaque élève, facilitant un suivi personnalisé et une intervention ciblée.",
        date: "2024",
        status: "Opérationnel",
        imageUrl: DetailEleveGif,
      },
];

export const product2 = [
  {
      id: "0",
      title: "Espace Prof : Création d'une évaluation bilan",
      text: "Permet aux professeurs de créer des évaluations bilan alignées sur les compétences de l'évaluation nationale et de visualiser les progressions de leur classe en temps réel.",
      date: "Décembre 2024",
      status: "Opérationnel",
      imageUrl: EvalBilanGif,
      colorful: true,
    },
    {
      id: "1",
      title: "Suivi des progressions de la classe",
      text: "Un tableau de bord dédié pour les professeurs, leur permettant d'analyser les progrès de la classe sur chaque compétence, afin de mieux cibler les points d'amélioration.",
      date: "Janvier 2025",
      status: "Opérationnel",
      imageUrl: ProgressionBilanGif,
    },
    {
      id: "2",
      title: "Espace Élève : Évaluations bilan en temps limité",
      text: "Permet aux élèves de passer les évaluations bilan créées par le professeur, avec un temps imparti pour chaque épreuve afin de simuler des conditions d'évaluation réelles.",
      date: "Mars 2025",
      status: "Opérationnel",
      imageUrl: QCMInteractifGif,
    },
    {
      id: "3",
      title: "Correction instantanée post-QCM",
      text: "Offre aux élèves une vue immédiate sur les corrections de leur évaluation dès qu'ils ont terminé le QCM, avec des explications pour chaque réponse.",
      date: "Mai 2025",
      status: "Opérationnel",
      imageUrl: FeedBackGif,
    },
];

export const product3 = [
  {
      id: "0",
      title: "Création de groupes de travail hétérogènes ou homogènes",
      text: "Permet de constituer des groupes adaptés en 4ème et 3ème pour des plans de travail en classe, favorisant la collaboration et le renforcement ciblé des compétences.",
      date: "Décembre 2024",
      status: "Opérationnel",
      imageUrl: GroupeBesoinsGif,
      colorful: true,
    },
    {
      id: "1",
      title: "Administrez vos groupes de besoins (6e & 5e)",
      text: "Administrez facilement vos groupes de besoins, concevez vos barrettes, attribuez les professeurs et interchangez les élèves d'un groupe à l'autre en toute simplicité.",
      date: "Mars 2025",
      status: "Opérationnel",
      imageUrl: AdminGroupGif,
    },
    {
      id: "2",
      title: "Analyse et Gestion Optimisée des Groupes de Besoins pour les 6èmes et 5èmes",
      text: "Propose un suivi détaillé des groupes de besoins en 6ème et 5ème, offrant un outil de suivi individuel des élèves pour une prise en charge différenciée et efficace.",
      date: "Janvier 2025",
      status: "Opérationnel",
      imageUrl: OutilSuiviGif,
    },
];

export const product4 = [
  {
      id: "0",
      title: "Suivi de la progression commune et en classe",
      text: "Permet de suivre la progression de la classe dans son ensemble, en visualisant les avancées sur les compétences clés et les objectifs fixés.",
      date: "Décembre 2024",
      status: "Opérationnel",
      imageUrl: ProgressionCommuneGif,
      colorful: true,
    },
    {
      id: "1",
      title: "Progression spiralée et calée sur les attendus",
      text: "Propose une progression en spirale alignée avec les attendus d'Eduscol, intégrant des activités d'automatisation, de résolution de problèmes et des questions flash pour renforcer les acquis.",
      date: "Janvier 2025",
      status: "Opérationnel",
      imageUrl: SpiraleGif,
    },
];
  export const pricing = [
    {
      id: "0",
      title: "Simplifié",
      description: "58.30€/mois ",
      price: "700",
      features: [
        "Gestion complète des évaluations avec import et export depuis Pronote",
        "Analyse des évaluations nationales et évaluation bilan pour suivre la progression",
        "Planification détaillée de la progression des élèves",
        "Tableau de bord complet pour suivre l'acquisition des compétences et savoirs",
        "Espace coordonateur de REP - filtrage des évaluations nationales"
      ],
    },
    {
      id: "1",
      title: "Complet",
      description: "83.30€/mois",
      price: "1 000",
      features: [
        "Mode simplifié inclus",
        "Externalisation des importations (élèves, évaluations nationales, etc.) ",
        "Module français inclus"
      ],
    },
    {
      id: "2",
      title: "Personnalisé",
      description: "Nombre de professeurs illimité.",
      price: null,
      features: [
        "Accès à toutes les fonctionnalités",
        "Recommandations personnalisées basées sur vos préférences"
      ],
    },
  ];


export const idea = [
  {
    id:"0",
    title:"Analyse rapide et intuitive",
    description:"Gagnez du temps avec une analyse tout-en-un des résultats de classe, visualisez pourcentages, réponses élèves et images associées sur une seule page.",
    image:idea1Logo,
  },
  {
    id:"1",
    title:"Création et correction automatique de QCM",
    description:"Créez des évaluations en ligne, corrigez automatiquement les QCM et évaluez la progression des élèves instantanément.",
    image:idea2Logo,
  },
  {
    id:"2",
    title:"Outils de différenciation pédagogique",
    description:" Mesurez la progression par rapport aux évaluations nationales et créez des progressions et groupes de besoins personnalisés pour chaque élève.",
    image:idea3Logo,
  },{
    id:"3",
    title:"Collaboration et gestion simplifiée",
    description:"Partagez et modifiez des plans de progression, suivez la réussite par thème, et gérez des groupes de besoins avec des scores détaillés.",
    image:idea4Logo,
  }
]


export const solutions = [
  {
  id:"0",
  title:"Exploiter vos évaluations",
  description:" Optimisez l'évaluation avec TanoEd : de l'importation simplifiée à l'analyse détaillée, facilitez le suivi du progrès éducatif avec précision et efficacité.",
  image:imageDash,
  icon:solution1Logo,
},
{
  id:"1",
  title:"Création et correction automatique de QCM",
  description:"Créez des évaluations en ligne, corrigez automatiquement les QCM et évaluez la progression des élèves instantanément.",
  image:imageEvalNat,
  icon:solution2Logo,
},
{
  id:"2",
  title:"Outils de différenciation pédagogique",
  description:" Mesurez la progression par rapport aux évaluations nationales pour créer des progressions personnalisés pour chaque élève et des groupes de besoins.",
  image:imageGroupe,
  icon:solution3Logo,
},{
  id:"3",
  title:"Collaboration et gestion simplifiée",
  description:"Partagez et modifiez des plans de progression, suivez la réussite par thème, et gérez des groupes de besoins avec des scores détaillés.",
  image:imageProgress,
  icon:solution4Logo,
}
]


export const fonctionnalités1 = [

]